import milk from './images/memes/milk.png'
import pvp from './images/memes/pvp.png'
import upvotes from './images/memes/upvotes.png'
import cpvsvmt from './images/memes/cpvsvmt.png'
import batman from './images/memes/batman.png'
import allthecoffee from './images/memes/allthecoffee.png'
import gmvmt from './images/memes/gmvmt.png'
import watchinu from './images/memes/watchinu.png'
import lazereyes from './images/memes/lazereyes.png'
import avengeru from './images/memes/avengeru.png'
import coffeetime from './images/memes/coffeetime.png'
import boomhead from './images/memes/boomhead.png'
import cowboyhat from './images/memes/cowboyhat.png'
import guns from './images/memes/guns.png'
import point from './images/memes/point.png'
import uwu from './images/memes/uwu.png'
import incominggg from './images/memes/incominggg.png'
import watchingclosely from './images/memes/watchingclosely.png'
import vmdrake from './images/memes/vmdrake.png'
import chad from './images/memes/chad.png'
import takeit from './images/memes/takeit.png'
import runnn from './images/memes/runnn.png'
import yelling from './images/memes/yelling.png'
import smol from './images/memes/smol.png'
import popevmt from './images/memes/popevmt.png'
import gmpixel from './images/memes/gmpixel.png'
import gm3 from './images/memes/gm3.png'
import danger from './images/memes/danger.png'
import oprah from './images/memes/oprah.png'
import active from './images/memes/active.png'
import isawthat from './images/memes/isawthat.png'
import coffeee from './images/memes/coffeee.png'
import fightme from './images/memes/fightme.jpg'
import holysoda from './images/memes/holysoda.png'
import wtbsodas from './images/memes/wtbsodas.png'
import hi5forsoda from './images/memes/hi5forsoda.png'
import oursoda from './images/memes/oursoda.png'
import runrun from './images/memes/runrun.png'
import fridayfeel from './images/memes/fridayfeel.png'
import askingforsodas from './images/memes/askingforsodas.png'
import batmangm from './images/memes/batmangm.png'
import chuckapproved from './images/memes/ChuckApproved.png'
import comeatmebro from './images/memes/comeatmebro.png'
import emotionaldmtg from './images/memes/emotionaldmtg.png'
import gm6 from './images/memes/gm6.png'
import gmepepee from './images/memes/gmepepee .png'
import gmkanye from './images/memes/gmkanye.png'
import haveasoda from './images/memes/haveasoda.png'
import hulkgm from './images/memes/hulkgm.png'
import jokerscene from './images/memes/jokerscene.png'
import moreairdrops from './images/memes/moarairdrops.png'
import ok from './images/memes/ok.png'
import olreliable from './images/memes/olreliable.png'
import mondaygm from './images/memes/mondaygm.png'
import peace from './images/memes/peace.png'
import reasons from './images/memes/reasons.png'
import ripmoment from './images/memes/ripmoment.png'
import signguy from './images/memes/signguy.png'
import sodaswinter from './images/memes/sodaswinter.png'
import tellmemore from './images/memes/tellmemore.png'
import vaultgallery from './images/memes/vaultgallery.png'
import vmhulk from './images/memes/vmhulk.png'
import wersodas from './images/memes/wersodas.png'
import you3 from './images/memes/you3.png'


const memes = [
  {
    name: 'You',
    image: you3,
    position: {
      x: 166,
      y: 208
    },
    scale: 0.25,
    rotate: 0,
    onlyFace: true
  },
  {
    name: 'WeAreSodas',
    image: wersodas,
    position: {
      x: 101,
      y: 193
    },
    scale: 0.47,
    rotate: 10,
    onlyFace: true
  },
  {
    name: 'VMHulk',
    image: vmhulk,
    position: {
      x: 34,
      y: 66
    },
    scale: 0.35,
    rotate: 10,
    onlyFace: true
  },
  {
    name: 'VaultGallery',
    image: vaultgallery,
    position: {
      x: 203,
      y: 131
    },
    scale: 0.25,
    rotate: 0,
    onlyFace: true
  },
  {
    name: 'TellMeMore',
    image: tellmemore,
    position: {
      x: 218,
      y: -48
    },
    scale: 0.40,
    rotate: 15,
    onlyFace: true
  },
  // {
  //   name: 'SodasWinter',
  //   image: sodaswinter,
  //   position: {
  //     x: 20,
  //     y: 80
  //   },
  //   scale: 0.60,
  //   rotate: -5,
  //   onlyFace: true
  // },
  // {
  //   name: 'SignGuy',
  //   image: signguy,
  //   position: {
  //     x: 173,
  //     y: 129
  //   },
  //   scale: 0.30,
  //   rotate: 5,
  //   onlyFace: true
  // },
  // {
  //   name: 'RIPMoment',
  //   image: ripmoment,
  //   position: {
  //     x: 142,
  //     y: 46
  //   },
  //   scale: 0.35,
  //   rotate: 0,
  //   onlyFace: true
  // },
  // {
  //   name: 'Reasons',
  //   image: reasons,
  //   position: {
  //     x: 172,
  //     y: -42
  //   },
  //   scale: 0.30,
  //   rotate: 7,
  //   onlyFace: true
  // },
  // {
  //   name: 'Peace',
  //   image: peace,
  //   position: {
  //     x: -49,
  //     y: -7
  //   },
  //   scale: 0.70,
  //   rotate: 5,
  //   onlyFace: true
  // },
  // {
  //   name: 'MondayGM',
  //   image: mondaygm,
  //   position: {
  //     x: 195,
  //     y: 25
  //   },
  //   scale: 0.50,
  //   rotate: 7,
  //   onlyFace: true
  // },
  // {
  //   name: 'OlReliable',
  //   image: olreliable,
  //   position: {
  //     x: -49,
  //     y: 269
  //   },
  //   scale: 0.25,
  //   rotate: -40,
  //   onlyFace: false
  // },
  // {
  //   name: 'OK',
  //   image: ok,
  //   position: {
  //     x: 23,
  //     y: 186
  //   },
  //   scale: 0.60,
  //   rotate: -5,
  //   onlyFace: true
  // },
  // {
  //   name: 'MoreAirDrops',
  //   image: moreairdrops,
  //   position: {
  //     x: -16,
  //     y: 41
  //   },
  //   scale: 0.55,
  //   rotate: -5,
  //   onlyFace: true
  // },
  // {
  //   name: 'JokerScene',
  //   image: jokerscene,
  //   position: {
  //     x: 165,
  //     y: 1
  //   },
  //   scale: 0.35,
  //   rotate: -5,
  //   onlyFace: true
  // },
  // {
  //   name: 'GMHulk',
  //   image: hulkgm,
  //   position: {
  //     x: -15,
  //     y: 135
  //   },
  //   scale: 0.55,
  //   rotate: -7,
  //   onlyFace: true
  // },
  // {
  //   name: 'HaveASoda',
  //   image: haveasoda,
  //   position: {
  //     x: 46,
  //     y: 16
  //   },
  //   scale: 0.55,
  //   rotate: -5,
  //   onlyFace: true
  // },
  // {
  //   name: 'GMKanye',
  //   image: gmkanye,
  //   position: {
  //     x: 101,
  //     y: -25
  //   },
  //   scale: 0.50,
  //   rotate: 0,
  //   onlyFace: true
  // },
  // {
  //   name: 'GMPepee',
  //   image: gmepepee,
  //   position: {
  //     x: 200,
  //     y: 102
  //   },
  //   scale: 0.70,
  //   rotate: 20,
  //   onlyFace: true
  // },
  // {
  //   name: 'GM6',
  //   image: gm6,
  //   position: {
  //     x: 154,
  //     y: 103
  //   },
  //   scale: 0.70,
  //   rotate: 0,
  //   onlyFace: true
  // },
  // {
  //   name: 'EmotionalDmg',
  //   image: emotionaldmtg,
  //   position: {
  //     x: 78,
  //     y: 58
  //   },
  //   scale: 0.60,
  //   rotate: -2,
  //   onlyFace: true
  // },
  {
    name: 'ChuckApproved',
    image: chuckapproved,
    position: {
      x: 152,
      y: 40
    },
    scale: 0.45,
    rotate: 7,
    onlyFace: true
  },
  {
    name: 'BatmanGM',
    image: batmangm,
    position: {
      x: 207,
      y: 65
    },
    scale: 0.50,
    rotate: 7,
    onlyFace: true
  },
  {
    name: 'AskingForSodas',
    image: askingforsodas,
    position: {
      x: 84,
      y: -15
    },
    scale: 0.65,
    rotate: 0,
    onlyFace: true
  },
  {
    name: 'FridayFeel',
    image: fridayfeel,
    position: {
      x: 87,
      y: 66
    },
    scale: 0.55,
    rotate: 0,
    onlyFace: true
  },
  {
    name: 'ComeAtMeBro',
    image: comeatmebro,
    position: {
      x: 111,
      y: -11
    },
    scale: 0.40,
    rotate: 0,
    onlyFace: true
  },
  {
    name: 'RunRun',
    image: runrun,
    position: {
      x: 235,
      y: -45
    },
    scale: 0.35,
    rotate: 10,
    onlyFace: true
  },
  {
    name: 'OurSoda',
    image: oursoda,
    position: {
      x: 125,
      y: 130
    },
    scale: 0.3,
    onlyFace: true
  },
  {
    name: 'Hi5ForSoda',
    image: hi5forsoda,
    position: {
      x: 94,
      y: 135
    },
    scale: 0.5,
    onlyFace: true
  },
  {
    name: 'HolySoda',
    image: holysoda,
    position: {
      x: 220,
      y: -15
    },
    scale: 0.3,
    onlyFace: true
  },
  {
    name: 'FightMe',
    image: fightme,
    position: {
      x: 35,
      y: -25
    },
    scale: 0.4,
    onlyFace: true
  },
  {
    name: 'Coffee',
    image: coffeee,
    position: {
      x: 50,
      y: -5
    },
    scale: 0.4,
    onlyFace: true
  },
  {
    name: 'WTBSodas',
    image: wtbsodas,
    position: {
      x: 78,
      y: -13
    },
    scale: 0.25,
    onlyFace: true
  },
  {
    name: 'Oprah',
    image: oprah,
    position: {
      x: 135,
      y: 85
    },
    scale: 0.35,
    onlyFace: true
  },
  {
    name: 'I Saw That',
    image: isawthat,
    position: {
      x: -40,
      y: 185
    },
    scale: 0.7,
    onlyFace: false,
    rotate: 10
  },
  {
    name: 'Active',
    image: active,
    position: {
      x: 0,
      y: 0
    },
    scale: 1.0,
    onlyFace: false,
    onTop: false
  },
  {
    name: 'Danger',
    image: danger,
    position: {
      x: 114,
      y: 80
    },
    scale: 0.35,
    onlyFace: true
  },
  {
    name: 'GM3',
    image: gm3,
    position: {
      x: 37,
      y: 60
    },
    scale: 0.7,
    onlyFace: true
  },
  {
    name: 'GmPixel',
    image: gmpixel,
    position: {
      x: 5,
      y: 120
    },
    scale: 0.7,
    onlyFace: false,
    onTop: false
  },
  {
    name: 'Smol',
    image: smol,
    position: {
      x: 175,
      y: 180
    },
    scale: 0.1
  },
  {
    name: 'Yelling',
    image: yelling,
    position: {
      x: 275,
      y: 160
    },
    scale: 0.2
  },
  {
    name: 'PopeVMT',
    image: popevmt,
    position: {
      x: 104,
      y: 100
    },
    scale: 0.15,
    rotate: -5
  },
  {
    name: 'Runnn',
    image: runnn,
    position: {
      x: 178,
      y: 106
    },
    scale: 0.2
  },
  {
    name: 'Take It',
    image: takeit,
    position: {
      x: 102,
      y: 102
    },
    scale: 0.4,
    rotate: 15
  },
  {
    name: 'Chad',
    image: chad,
    position: {
      x: 30,
      y: -80
    },
    scale: 0.75,
    rotate: 15
  },
  {
    name: 'Incominggg',
    image: incominggg,
    position: {
      x: 165,
      y: 205
    },
    scale: 0.075,
    rotate: -12,
    opacity: 0.75
  },
  {
    name: 'VMT Drake',
    image: vmdrake,
    position: {
      x: 225,
      y: 225
    },
    scale: 0.38,
    onlyFace: false
  },
  {
    name: 'Watching',
    image: watchingclosely,
    position: {
      x: 40,
      y: -80
    },
    scale: 0.65,
    rotate: 15
  },
  {
    name: 'VS',
    image: cpvsvmt,
    position: {
      x: -30,
      y: 220
    },
    scale: 0.25,
    rotate: -10
  },
  {
    name: 'Milk',
    image: milk,
    position: {
      x: 135,
      y: 150
    },
    scale: 0.5
  },
  {
    name: 'PvP',
    image: pvp,
    position: {
      x: 225,
      y: 128
    },
    scale: 0.38,
    onlyFace: false
  },
  {
    name: 'Upvotes',
    image: upvotes,
    position: {
      x: 145,
      y: 180
    },
    scale: 0.35,
    rotate: 5
  },
  {
    name: 'CowboyHat',
    image: cowboyhat,
    position: {
      x: 0,
      y: 0
    },
    scale: 1.0,
    onlyFace: false,
    onTop: false
  },
  {
    name: 'Guns',
    image: guns,
    position: {
      x: 0,
      y: 0
    },
    scale: 1.0,
    onlyFace: false,
    onTop: false
  },
  {
    name: 'Point',
    image: point,
    position: {
      x: 0,
      y: 0
    },
    scale: 1.0,
    onlyFace: false,
    onTop: false
  },
  {
    name: 'Uwu',
    image: uwu,
    position: {
      x: 0,
      y: 0
    },
    scale: 1.0,
    onlyFace: false,
    onTop: false
  },
  {
    name: 'CoffeeTime',
    image: coffeetime,
    position: {
      x: 200,
      y: 60
    },
    scale: 0.35
  },
  {
    name: 'AvengerU',
    image: avengeru,
    position: {
      x: 75,
      y: 100
    },
    scale: 0.5,
    onlyFace: true,
    rotate: -5,
    opacity: 0.6
  },
  {
    name: 'Boomhead',
    image: boomhead,
    position: {
      x: 0,
      y: 0
    },
    scale: 1.0,
    onlyFace: false,
    onTop: false
  },
  {
    name: 'LazerEyes',
    image: lazereyes,
    position: {
      x: 0,
      y: 0
    },
    scale: 1.0,
    onlyFace: false,
    onTop: false
  },
  {
    name: 'WatchinU',
    image: watchinu,
    position: {
      x: 155,
      y: 192
    },
    scale: 0.2,
    onlyFace: false,
    rotate: -11,
    opacity: 0.5
  },
  {
    name: 'GmVMT',
    image: gmvmt,
    position: {
      x: 190,
      y: 58
    },
    scale: 0.2
  },
  {
    name: 'AllTheCoffee',
    image: allthecoffee,
    position: {
      x: 60,
      y: 55
    },
    scale: 0.75,
    onlyFace: false,
    onTop: false
  }
]

export function getMeme(index) {
  return {
    scale: 1,
    rotate: 0,
    onTop: true,
    onlyFace: true,
    opacity: 1.0,
    ...memes[index]
  }
}

export const MEME_COUNT = memes.length
import { h, render } from "preact";
import { useState, useEffect } from 'preact/hooks';
import styles from '../style.css'
import { ethers } from 'ethers'
import nftABI from '../nft_abi.json'
import globalWalletABI from '../globalWalletAbi.json'
import wlSigs from '../wlSigs.json'
import vendingMachine from '../images/vending1.png'
import memeMachine from '../images/mememachine.png'
import speechBubble from '../images/SpeechBubble.png'
import { nftContractAddress, onPerformTransaction } from "../Contracts";
import Home from '../pages/home'
import Meme from '../pages/meme'
import { ONLY_MEME_MACHINE } from "../Config";

const ethereum = (window as any).ethereum

export default () => {
  const [connected, setConnected] = useState(0)
  const [selectedPage, setSelectedPage] = useState(ONLY_MEME_MACHINE ? 1 : 0)
  const [ownNFT, setOwnNFT] = useState(undefined)

  async function checkWallet(address_, globalWalletContract_) {
    if(ONLY_MEME_MACHINE) {
      setOwnNFT([{toNumber: () => 1731}])
      return
    }

    const nftArray = await globalWalletContract_.walletOfOwner(nftContractAddress, address_, 0)
    setOwnNFT([...nftArray])
  }

  async function onConnect(signer) {
    const globalWalletContract = new ethers.Contract('0x7E574b3f77bB08aF239C65F04847ef0Fe519ECF3', globalWalletABI, signer)
    const address = await signer.getAddress()
    setConnected(1)
    checkWallet(address, globalWalletContract)
  }  

  async function performConnect() {
    await ethereum.request({ method: 'eth_requestAccounts' })
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const accounts = await provider.listAccounts()
    if (accounts.length > 0) {
      onConnect(signer)
    } else {
      setConnected(1)
    }
  }

  useEffect(() => {
    const onDocumentLoad = async () => {       
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const accounts = await provider.listAccounts()
        if (accounts.length > 0) {
          const signer = provider.getSigner()
          await onConnect(signer)
        } else {
          await performConnect()
        }
      }
    }

    onDocumentLoad()
  }, []);

  const pages = [
    (<Home ownNFT={ownNFT} setSelectedPage={setSelectedPage} />),
    (<Meme ownNFT={ownNFT} setSelectedPage={setSelectedPage} />)
  ]

  return (<div>
      <div className={styles.renderCanvas} />
      <div className={styles.cover}>
      
      {connected ? 
          (
           pages[selectedPage] ) :
          (
            <div id="disconnected" className={styles["mint-container"]}>
            <div>
              <div style={ {'margin-bottom': '32px'} }>
                <b id={styles["mint-header"]}>Vending Machine Tycoon</b>
              </div>
              <div id="connect-message" style={ {'margin-bottom': '24px'} }>Connect first below</div>
              <div className={styles["button"]} id={styles["connect-button"]} onClick={
                async () => {
                  await performConnect()
                }
              }>
                CONNECT
              </div>
              <img src={vendingMachine} style={{position: 'absolute', left: '190px', top: '350px'}}></img>
            </div>
          </div>)}
        </div>

      </div>);
}
import { h, render } from "preact";
import { useState, useRef, useEffect } from 'preact/hooks';
import styles from '../style.css'
import { getMeme, MEME_COUNT } from '../Memes'
import { ONLY_MEME_MACHINE } from "../Config";

const PNG_BASE_URL = './avatars'

const loadImage = (url) => new Promise((resolve, reject) => {
  const img = new Image();
  img.addEventListener('load', () => resolve(img));
  img.addEventListener('error', (err) => reject(err));
  img.src = url;
})

async function drawImageOnCanvas(image, context, x, y, width, height, rotate) {
  const imageObj = await loadImage(image)
  context.translate(width/2,height/2);
  context.rotate(rotate*Math.PI/180);
  context.translate(-width/2,-height/2);
  context.globalAlpha = 1.0;
  context.drawImage(imageObj, x, y, width, height);
  context.setTransform(1,0,0,1,0,0)
}


async function drawNFTImageOnCanvas(image, context, x, y, clipHeight, clipWidth, width, height, rotate) {
  const imageObj = await loadImage(image)
  context.translate(width/2,height/2);
  context.rotate(rotate*Math.PI/180);
  context.translate(-width/2,-height/2);
  context.drawImage(imageObj, 0, 0, clipHeight, clipWidth, x, y, width, height);
  context.setTransform(1,0,0,1,0,0)
}

async function handleCanvas(canvasRef, nftId, memeIndex, overridePos) {
  const context = canvasRef.current.getContext('2d');
  const size = 400

  context.setTransform(1,0,0,1,0,0)
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  const meme = getMeme(memeIndex)

  if(meme.onTop) {
    await drawImageOnCanvas(meme.image, context, 0, 0, size, size, 0)
  }

  const nftUrl = `${PNG_BASE_URL}/${nftId}.png`
  const nftWidth = size * meme.scale
  const nftHeight = meme.onlyFace ? nftWidth * (258/320) : nftWidth
  context.globalAlpha = meme.opacity;

  let pos = meme.position
  if(overridePos !== undefined) {
    pos = overridePos
  }
  await drawNFTImageOnCanvas(nftUrl, context, pos.x, pos.y, 320, meme.onlyFace ? 258 : 320, nftWidth, nftHeight, meme.rotate)
  context.globalAlpha = 1.0;

  if(!meme.onTop) {
    await drawImageOnCanvas(meme.image, context, 0, 0, size, size, 0)
  }
}

export default (props) => {
    const { ownNFT, setSelectedPage } = props
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [selectedMeme, setSelectedMeme] = useState(0)
    const [overridePos, setOverridePos] = useState(undefined)
    let nftId = undefined;
    if(ownNFT != undefined) {
      nftId = ownNFT[selectedIndex].toNumber()
    } 

    const canvasRef = useRef(null);

    useEffect(() => {
      handleCanvas(canvasRef, nftId, selectedMeme, overridePos)
        }, [nftId, selectedMeme, overridePos]);

    return (<div className={styles["section"]} id={styles["meme"]}>
    <div className={styles["mint-container"]}>
    <div>
              <div style={ {'margin-bottom': '10px', backgroundColor: 'rgba(0,0,0,0.5)', paddingLeft:'20px', paddingRight: '20px'} }>
                <b id={styles["mint-header"]}>MEME MACHINE</b>
              </div>
              <canvas width="400" height="400"  ref={canvasRef} id={styles["meme-canvas"]}
              
              onClick={
                event => {
                  if(ONLY_MEME_MACHINE) {
                    const boundingRect = event.currentTarget.getBoundingClientRect()
                    const overrideMemePosition = { x: Math.round(event.pageX - boundingRect.x - 50), y: Math.round(event.pageY - boundingRect.y - 50) }
                    console.log(JSON.stringify(overrideMemePosition))
                    setOverridePos(overrideMemePosition)
                  } else {
                    var link = document.createElement("a");
                    let uri = canvasRef.current.toDataURL('image/png');
                    link.setAttribute('download', `meme_${getMeme(selectedMeme).name}_${nftId}.png`);
                    link.href = uri;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }
                }
              }

              />
              <a href="https://opensea.io/collection/vee-em-tee"><img src='https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png' style={{maxWidth: 25, position: 'absolute', right: '10px', top: '20px', cursor: 'pointer'}}></img></a>
              <a onClick={() => setSelectedPage(0)} style={{ fontWeight: '1000', textDecoration: 'none', maxWidth: 25, position: 'absolute', left: '5px', top: '13px', cursor: 'pointer'}}>◄</a>
            
            <div style={{ fontSize: '10px', width: 400, position: 'absolute', left: '50px', bottom: '127px' }}>Click on the above image to download</div>

            <img src='./arrow-left.png' style={{ width: 30, position: 'absolute', left: '142px', bottom: '78px', cursor: 'pointer' }} onClick={
              () => {
                setSelectedMeme(selectedMeme === 0 ? MEME_COUNT - 1 : selectedMeme - 1)
              }
            }></img>
            <img src='./arrow-right.png' style={{ width: 30, position: 'absolute', left: '337px', bottom: '78px', cursor: 'pointer' }} onClick={
              () => {
                setSelectedMeme(selectedMeme === MEME_COUNT - 1 ? 0 : selectedMeme + 1)
              }
            }></img>
            
            <div style={{
              width: 160, height: 75, position: 'absolute', left: '175px', bottom: '25px',
              display: 'block', lineHeight: '1.75',
              backgroundSize: 'contain', backgroundImage: `url(./button.png)`, backgroundRepeat: 'no-repeat', fontSize: '10px', paddingTop: '32px'
            }}>{getMeme(selectedMeme).name}</div>

          {ownNFT && ownNFT.length > 1 ?
            (<div>
            <img src='./arrow-left.png' style={{ width: 30, position: 'absolute', left: '142px', bottom: '18px', cursor: 'pointer' }} onClick={
              () => {
                setSelectedIndex(selectedIndex === 0 ? ownNFT.length - 1 : selectedIndex - 1)
              }
            }></img>
            <img src='./arrow-right.png' style={{ width: 30, position: 'absolute', left: '337px', bottom: '18px', cursor: 'pointer' }} onClick={
              () => {
                setSelectedIndex(selectedIndex === ownNFT.length - 1 ? 0 : selectedIndex + 1)
              }
            }></img>
            </div>) : (<div></div>)}

            
            <div style={{
              width: 160, height: 75, position: 'absolute', left: '175px', bottom: '-35px',
              display: 'block', lineHeight: '1.75',
              backgroundSize: 'contain', backgroundImage: `url(./button.png)`, backgroundRepeat: 'no-repeat', fontSize: '10px', paddingTop: '32px'
            }}>{nftId !== undefined ? `VMT #${nftId}` : 'Loading...'}</div>

            </div></div></div>)
}
import { h, render } from "preact";
import { useState, useEffect } from 'preact/hooks';
import styles from '../style.css'
import { ethers } from 'ethers'
import vendingMachine from '../images/vending1.png'
import memeMachine from '../images/mememachine.png'
import speechBubble from '../images/SpeechBubble.png'


export default (props) => {
  const { ownNFT, setSelectedPage } = props
  const [clickKai, setClickKai] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  let speech = (<span>Try out our<br /> MEME MACHINE <br /> on the left!</span>)
  let nftId = undefined;

  if (ownNFT != undefined) {
    if (ownNFT.length > 0) {
      nftId = ownNFT[selectedIndex].toNumber()
    } else {
      speech = (<span>You own no VMT.<br />Grab one or more<br />and come back</span>)
    }
  }

  if (clickKai) {
    speech = (<span>We actually face<br /> to the right <br /> normally</span>)
  }

  return (<div className={styles["section"]} id={styles["mint"]}>
    <div className={styles["mint-container"]}></div>
    <div>
      <div style={{ 'margin-bottom': '10px' }}>
        <b id={styles["mint-header"]}>Vending Machine Tycoon</b>
      </div>
      <div id="connect-message" style={{ 'margin-bottom': '5px' }}></div>
      <img src={vendingMachine} style={{ position: 'absolute', left: '190px', top: '350px' }}></img>

      <div style={{
        width: '300px', lineHeight: '1.8em', color: 'black', fontSize: '12px', height: '300px', paddingTop: '85px', position: 'absolute', display: 'block',
        backgroundSize: 'contain', backgroundImage: `url(${speechBubble})`, backgroundRepeat: 'no-repeat', left: '185px', top: '290px', opacity: 0.9
      }}>
        {speech}
      </div>
      <img src='./guy.gif' style={{ maxWidth: 100, position: 'absolute', left: '380px', bottom: '-1px', cursor: 'pointer' }} onClick={
        () => { setClickKai(!clickKai) }
      }></img>


      <a href="https://opensea.io/collection/vee-em-tee"><img src='https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png' style={{ maxWidth: 25, position: 'absolute', right: '10px', top: '20px', cursor: 'pointer' }}></img></a>

      {nftId != undefined ?
        (


          <div>
            <img src={memeMachine} style={{ position: 'absolute', left: '50px', top: '347px', cursor: 'pointer' }} onClick={() => {
              setSelectedPage(1)
            }}></img>

            {ownNFT.length > 1 ?
            (<div>
            <img src='./arrow-left.png' style={{ width: 30, position: 'absolute', left: '142px', bottom: '18px', cursor: 'pointer' }} onClick={
              () => {
                setSelectedIndex(selectedIndex === 0 ? ownNFT.length - 1 : selectedIndex - 1)
              }
            }></img>
            <img src='./arrow-right.png' style={{ width: 30, position: 'absolute', left: '337px', bottom: '18px', cursor: 'pointer' }} onClick={
              () => {
                setSelectedIndex(selectedIndex === ownNFT.length - 1 ? 0 : selectedIndex + 1)
              }
            }></img>
            </div>) : (<div></div>)}
            <div style={{
              width: 160, height: 75, position: 'absolute', left: '175px', bottom: '-25px', cursor: 'pointer',
              display: 'block', lineHeight: '1.75',
              backgroundSize: 'contain', backgroundImage: `url(./button.png)`, backgroundRepeat: 'no-repeat', fontSize: '10px', paddingTop: '22px'
            }} onClick={
              () => {
                var link = document.createElement("a");
                let uri = `https://nftstorage.link/ipfs/bafybeiapu7zjgltxzj43n5bgaskvm4464z3alzkqsloykslrp6uuyw7yam/${nftId}.png`
                link.setAttribute('download', `${nftId}.gif`);
                link.href = uri;
                document.body.appendChild(link);
                link.click();
                link.remove();
              }
            }>Download<br />PNG</div>

            <img src={`https://bafybeibqugkdtcjhus46iu72rtgg3tpmshclwuuhvsuymccgmkzzseddhy.ipfs.nftstorage.link/${nftId}.gif`} style={{ maxWidth: 100, position: 'absolute', left: '0px', bottom: '-1px', cursor: 'pointer' }}
              onClick={
                () => {
                  var link = document.createElement("a");
                  let uri = `https://bafybeibqugkdtcjhus46iu72rtgg3tpmshclwuuhvsuymccgmkzzseddhy.ipfs.nftstorage.link/${nftId}.gif`
                  link.setAttribute('download', `${nftId}.gif`);
                  link.href = uri;
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                }
              }
            ></img>
          </div>



        ) : (<div></div>)}
    </div>
  </div>)
}
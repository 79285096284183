import Router, { Route } from 'preact-router';
import { h, render } from 'preact';
import NFT from './pages/nft'
import Game from './pages/game'
/** @jsx h */

const Main = () => (
  <Router>
    <Route path="/" component={Game} />
    <Route path="/nft" component={NFT} />
  </Router>
);

render(<Main />, document.body);
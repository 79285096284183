import Toastify from 'toastify-js'
import retry from 'async-retry'

export function onTxError(message) {
  if (message.indexOf('Already minted') != -1) {
    message = 'Only 1 mint per wallet, try a different wallet'
  }

  return Toastify({
    text: message,
    duration: 20000,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      background: "linear-gradient(to right, #F58828, #FF8875)"
    }
  })
}

export function onTxProgress(message, link?) {
  return Toastify({
    text: message,
    duration: -1,
    close: false,
    onClick: () => {
      window.open(link, '_blank').focus()
    },
    gravity: "bottom", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      background: "linear-gradient(to right, #48C528, #48CF75)"
    }
  })
}

export function onPerformTransaction(actionCallback, onSuccess, message) {
  return async () => {
    try {
      const transaction = await actionCallback()
      const toast = onTxProgress(`${message}... (Click for Etherscan)`, `https://etherscan.io/tx/${transaction.hash}`)
      toast.showToast()
      await transaction.wait()
      await onSuccess()
      toast.hideToast()
    } catch (e) {
      onTxError(e.message).showToast()
    }
  }
}

export const nftContractAddress = '0xf775fc978969cef018baff9fa20c62eb6c9ec8bb';

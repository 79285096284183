html, body {
  width: 100%;
  height:100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 200;
}

.renderCanvas {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: black;
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;
}

.topLogo {
  color: black;
  font-size: 80px;
  padding-top: 25px;
  padding-left: 25px;
  min-width: 80%;
}

a {
  color: white;
}

a:visited {
  color: white;
}

.cover {
  width: 100%;
  min-height:100%;
  margin: 0px;
  padding: 0px;
  position: absolute;
  vertical-align: middle;
}

.gameCover {
  width: 100%;
  min-height:100%;
  margin: 0px;
  padding: 0px;
  position: absolute;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.5);
}

#header-bar {
  width: 100%;
  height: 120px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
}

.colored {
  width: 8px;
  height: 8px;
  border: 1px solid gray;
  display: inline-block;
}

.clay {
  background-color: rgb(168, 109, 0);
}

.stone {
  background-color: rgb(46, 46, 46);
}

.metal {
  background-color: rgb(179, 179, 179);
}

.obsidian {
  background-color: rgb(0, 0, 0);
}

.grass {
  background-color: rgb(8, 94, 0);
}

.bronze {
  background-color: rgb(199, 90, 0);
}

.jade {
  background-color: rgb(0, 255, 136);
}

.gold {
  background-color: rgb(214, 175, 0);
}

.ruby {
  background-color: rgb(231, 16, 62);
}

.sapphire {
  background-color: rgb(0, 140, 255);
}

.amethyst {
  background-color: rgb(179, 49, 255);
}

.diamond {
  background-color: rgb(255, 255, 255);
}

.no {
  background-color: rgb(100, 0, 0);
}

.yes {
  background-color: rgb(0, 100, 0);
}

#timer {
  text-align: center;
  font-size: 40px;
  margin-bottom: 24px;
  color: white;
}

#disconnected {
  justify-content: center;
}

#mint {
  text-align: center;
  font-size: 24px;
  color: white;
  background-size: cover;
  image-rendering: pixelated;

  background-image: url('./images/tennisCourt.png');
  left: 0;
  right: 0;              
  margin: auto;
  margin-top: 24px;
  min-width: 450px;
  min-height: 600px;
}

#game {
  text-align: center;
  font-size: 24px;
  color: white;
  background-size: cover;
  image-rendering: pixelated;

  background-image: url('./images/Alleyway.png');
  left: 0;
  right: 0;              
  margin: auto;
  margin-top: 24px;
  min-width: 450px;
  min-height: 600px;
}

#meme {
  text-align: center;
  font-size: 24px;
  color: white;
  background-size: cover;
  image-rendering: pixelated;

  background-image: url('./images/alleyway1.png');
  left: 0;
  right: 0;              
  margin: auto;
  margin-top: 24px;
  min-width: 450px;
  min-height: 600px;
}

#meme-canvas {
  width: 400px;
  height: 400px;
  margin: 50px;
  margin-top: 0px;
  border: 5px solid white;
  background-color: rgba(0,0,0,0.5);
  cursor: pointer;
}

.mint-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-top: 16px;
}

.status-text {
  font-size: 18px;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}

.info-text {
  font-size: 18px;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
}

.info-wrapper {
  width: 100%;
  text-align: left;
}

.section {
  border: 1px solid gray;
  background-color: rgba(0, 0, 0, .25);
  color: white;
  margin: 20px;
  padding: 20px;
  padding-top: 0px;
  font-family: 'Press Start 2P', sans-serif;
  width: 200px;
  position: absolute;
}

.gameSection {
  border: 1px solid gray;
  background-color: rgba(0, 0, 0, .25);
  color: white;
  margin: 20px;
  padding-top: 0px;
  font-family: 'Press Start 2P', sans-serif;
  width: 200px;
  position: absolute;
}

.button {
  border: 1px solid gray;
  background-color: #441166;
  opacity: 80%;
  color: white;
  margin: 8px;
  padding: 16px;
  font-size: 18px;
  font-family: 'Press Start 2P', sans-serif;
  width: auto;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}

.arrow {
  padding: 16px;
  padding-bottom: 24px;
}

.disabled {
  color: gray;
  cursor: default;
}

.row {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
}

#ethos-button { cursor: pointer; }
#elements-button { cursor: pointer; }
#faq-button { cursor: pointer; }
#roadmap-button { cursor: pointer; }

#mint-header {
  line-height: 1.5em;
  margin-top: 32px;
}

#clay-view {
  margin-top: 24px;
  height: 500px;
  width: 500px;
  border: 1px solid gray;
}


#button-container {
  padding-top: 12px;
  display: flex;
}

span {
  display: inline-block;
}

.socialIcon {
  width: 32px;
  height: 32px;
  padding: 25px;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav {
  margin-top: 16px;
  padding-left: 0;
  list-style: none;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, 100px)
}

.inactive-tab {
  color: gray;
}

.tab {
  width: 100px;
  display: block;
  margin: 5px;
  font-size: large;
  cursor: pointer;
  padding: 8px;
  border: 1px solid gray;
  margin-bottom: -12px;
}

@media only screen and (max-width: 1000px) {
  #ethos-button { display: block; }
  #elements-button { display: block; }
  #faq-button { display: block; }
  #roadmap-button { display: block; }

  .nav {
    display: grid;
  }

  .info-text {
    font-size: 14px;
  }

  /* #mint {
    margin: 20px;
    width: auto; 
    min-width: 200px;
  } */
  
  #clay-view {
    height: 70vw;
    width: 70vw;
  }
  
  #mint-header {
    margin-top: 48px;
  }

  #mint-count {
    margin-bottom: 32px;
  }

  /* .section {
    position: initial;
    width: auto;
  } */

  #timer {
    font-size: 30px;
  }
 
  .topLogo {
    min-width: 0px;
  }
  
  .socialIcon {
    width: 24px;
    height: 24px;
    padding: 20px;
  }
}
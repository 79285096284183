import { h, render } from "preact";
import { useState, useEffect } from 'preact/hooks';
import styles from '../style.css'
import { ethers } from 'ethers'
import vendingMachine from '../images/basicvm.png'
import android from '../images/android.png'
import memeMachine from '../images/mememachine.png'
import speechBubble from '../images/SpeechBubble.png'


export default (props) => {
  return (<div>
    <div className={styles.renderCanvas} />
      <div className={styles["gameSection"]} id={styles["game"]}>
      <div className={styles.gameCover}>
        <div className={styles["mint-container"]}></div>
          <div>
            <div style={{ 'margin-bottom': '10px' }}>
              <b id={styles["mint-header"]}>Vending Machine Tycoon</b>
            </div>
            </div>
          </div>
          
          <img src={vendingMachine} style={{position: 'absolute', width: '150px', left: '150px', top: '120px'}}></img>
          <a href="https://vmtgame.s3.ap-southeast-2.amazonaws.com/vmt.apk">
            <div className={styles["button"]} id={styles["connect-button"]} 
              style={{position: 'relative', width: '250px', left: '0px', top: '370px', paddingRight: '40px'}}>Download APK <img src={android} 
              style={{position: 'absolute', width: '30px', right: '8px', bottom: '10px'}}/></div>
          </a>
          <div className={`${styles["button"]} ${styles["disabled"]}`} id={styles["connect-button"]} 
            style={{position: 'relative', width: '280px', left: '0px', top: '370px'}}>iOS Coming soon</div>
          <a href="/nft">
            <div className={styles["button"]} id={styles["connect-button"]} 
              style={{position: 'relative', width: '280px', left: '0px', top: '370px'}}
          >Meme Machine</div></a>
      </div>
    </div>)
}